import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Dashboard/';

class DashboardService {
    getDashboardValues(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);

        UtilsRequest.get(API_URL + "GetDashboardValues", parametri,
        successFn,
        failFn
        );
    }
}

export default new DashboardService();