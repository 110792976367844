<template>
  <v-container id="dashboard" fluid tag="section">
    <!--
      <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1"
    >
      <template v-slot:[`item.glutenfree`]="{ item }">
        <v-simple-checkbox
          v-model="item.glutenfree"
          
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    -->
    <v-row>
      <v-col cols="12">
        <v-card-title class="black--text" style="font-size: 24px"
          >{{ $t("cambiaEnte") }}
        </v-card-title>
      </v-col>
    </v-row>
    <v-col style="max-width: 300px; padding-top: 20px" v-if="eEnti != null">
      <v-select
        :items="eEnti"
        return-object
        item-text="cDescrizione"
        item-value="id"
        :label="$t('ente')"
        v-model="enteSel"
        :disabled="shouldDisable"
        @change="onEnteChanged($event)"
      >
      </v-select>
    </v-col>
    <v-row>
      <v-divider class="mx-4"></v-divider>
    </v-row>

    <v-row>
      <v-divider class="mx-4"></v-divider>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-title class="black--text" style="font-size: 24px"
          >{{ $t("dashboard") }}
        </v-card-title>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-car-multiple"
          :title="$t('veicoli')"
          :loading="isloading_dashboard"
        >
          <v-card-text>{{ $t("totale") }}: {{ veicoliTot }} </v-card-text>
          <v-card-text
            >{{ $t("manutenzione") }}: {{ veicoliInManut }}</v-card-text
          >
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-2 mr-1 mdi mdi-clock theme--light"
              style="font-size: 16px"
            ></i>
            <span class="caption grey--text font-weight-light">{{
              $t("tutti")
            }}</span>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-toolbox"
          :title="$t('attrezzature.titolo')"
          :loading="isloading_dashboard"
        >
          <v-card-text>{{ $t("totale") }}: {{ attrezzTot }} </v-card-text>
          <v-card-text>{{ $t("manutenzione") }}: {{ attrezzInManut }}</v-card-text
          >
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-2 mr-1 mdi mdi-clock theme--light"
              style="font-size: 16px"
            ></i>
            <span class="caption grey--text font-weight-light">{{
              $t("tutti")
            }}</span>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-account-group"
          :title="$t('fornitori.titoloGriglia')"
          :loading="isloading_dashboard"
        >
          <v-card-text>{{ $t("totale") }}: {{ fornitoriTot }}</v-card-text>
          <v-card-text>{{ $t("budget") }}: {{ budgetUtilizzato }} / {{ budgetAnnuale }} €</v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-2 mr-1 mdi mdi-calendar theme--light"
              style="font-size: 16px"
            ></i>
            <span class="caption grey--text font-weight-light">
              {{ $t("annoCorrente") }}
            </span>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-calendar-alert"
          :title="$t('scadenze')"
        >
          <v-card-text>{{ $t("scadenze.inScadenza") }}: {{ scadInScadenza }} / {{ scadTotali }}</v-card-text>
          <v-card-text>{{ $t("scadenze.scadute") }}: {{ scadScaduta }} / {{ scadTotali }}</v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-2 mr-1 mdi mdi-calendar theme--light"
              style="font-size: 16px"
            ></i>
            <span class="caption grey--text font-weight-light">{{
              $t("tutti")
            }}</span>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-ev-station"
          :title="$t('rifornimenti.titolo')"
        >
          <v-card-text>{{ $t("meseScorso") }}: {{ rifornimentiMesePrecedenteChiusi }} / {{ rifornimentiMesePrecedenteTot }} chiusi </v-card-text>
          <v-card-text>{{ $t("meseCorrente") }}: {{ rifornimentiMeseCorrenteChiusi }} / {{ rifornimentiMeseCorrenteTot }} chiusi </v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-2 mr-1 mdi mdi-calendar theme--light"
              style="font-size: 16px"
            ></i>
          <span class="caption grey--text font-weight-light">{{
              $t("ultimiDueMesi")
            }}</span>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-currency-usd"
          :title="$t('page.buoni.titolo')"
          :loading="isloading_dashboard"
        >
          <v-card-text>{{ $t("buoniInLav") }}: {{ buoniLav }}</v-card-text>
          <v-card-text>{{ $t("buoniEmessi") }}: {{ buoniEmes }}</v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-2 mr-1 mdi mdi-calendar theme--light"
              style="font-size: 16px"
            ></i>
            <span class="caption grey--text font-weight-light">
              {{ $t("annoCorrente") }}
            </span>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-if="isSelezionaProfilo != null"
      v-model="dialogSelezionaRuolo"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h4; justify-left">{{ $t("ruoli") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" v-if="eRuoli != null">
                <v-select
                  :items="eRuoli"
                  item-value="CCode"
                  item-text="CDescription"
                  v-model="objRuoloSel"
                  label="Ruoli"
                  return-object
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onSelezionaProfiloClick">
            Seleziona
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      id="idAlertDashboard"
      shaped
      prominent
      :value="isAlertVisible"
      type="error"
    >
      {{ $t("erroreCaricamentoDati") }}
    </v-alert>
  </v-container>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import axios from "axios";
import { environment } from "@/environment/environment";
import i18n from "@/i18n";
import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import VeicoliService from "@/service/VeicoliService";
import AttrezzatureService from "@/service/AttrezzatureService";
import FornitoriService from "@/service/FornitoriService";
import BuoniService from "@/service/BuoniService";
import UtentiService from "@/service/UtentiService";
import AuthService from "@/service/AuthService";
import DashboardService from "@/service/DashboardService";

@Component
export default class Dashboard extends Vue {
  // Variabili
  eEnti = [];
  eRuoli = null;
  enteSel = null;

  veicoliTot = 0;
  attrezzTot = 0;
  fornitoriTot = 0;
  buoniLav = 0;
  buoniEmes = 0;
  veicoliInManut = 0;
  attrezzInManut = 0;
  budgetUtilizzato = 0;
  budgetAnnuale = 0;
  scadScaduta = 0;
  scadInScadenza = 0;
  scadTotali = 0;
  rifornimentiMesePrecedenteChiusi = 0;
  rifornimentiMesePrecedenteTot = 0;
  rifornimentiMeseCorrenteChiusi = 0;
  rifornimentiMeseCorrenteTot = 0;

  isAlertVisible = false;
  isloading_dashboard = false;
  shouldDisable = false;
    
  isSelezionaProfilo = false;
  dialogSelezionaRuolo = false;

  /*
@Watch('enteSel')
  watchedEnteSel() { 
  }

get enteSel() {return this.$store.state.enteSel}
*/
  mounted() {
    //this.loadAllObjects();
  }

  created() {
    //this.eEnti = this.$store.state.user.userEnti;
    // Verifico se l'utente è settato altrimenti faccio logout
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_DASHBOARD");
    switch (verLoadingPage)
    {
      case 0: // All OK
      case 2:
      case 3:
        // Verifico che ci sia un ruolo legato all'utente altrimenti faccio aprire la dialog di selezione
        if (this.$store.state.roleSel != null) {
              this.eRuoli = this.$store.state.roles;
              this.objRuoloSel = this.$store.state.roleSel;
          this.loadEntiByRuolo();
        } else {
          var ruoli = this.$store.state.roles;
          // Visualizzo la dialog per la selezione del profilo nel caso in cui l'utente è un multiprofilo
          if (!Utils.isNullOrUndefined(ruoli)) {
            if (ruoli.length > 1) {
              this.eRuoli = ruoli;
              this.objRuoloSel = ruoli[0];
              this.dialogSelezionaRuolo = true;
            } else {
              this.$store.commit("SET_ROLE_SELECTED", ruoli[0]);
              this.$store.commit("SET_PERMISSIONS", ruoli[0].UserPermissions);
              this.loadEntiByRuolo();
            }
          }
        }
        break;
      case 1:
        AuthService.logout();
        break;
    }

  }

  onEnteChanged(item) {
    this.$store.commit("SET_ENTE_SELECTED", this.enteSel);
    // Ricalcolo tutti gli oggetti legati al comune selezionato
    this.loadAllObjects();
  }

  onSelezionaProfiloClick() {
    this.$store.commit("SET_ROLE_SELECTED", this.objRuoloSel);
    this.$store.commit("SET_PERMISSIONS", this.objRuoloSel.UserPermissions);
    this.dialogSelezionaRuolo = false;
    this.loadEntiByRuolo();
  }

  hide_alert(event) {
    console.log("Hide alert");
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertVisible = false;
      console.log("hide alert after 5 seconds");
    }, 5000);
  }

  loadEntiByRuolo() {
    var idRuolo = this.$store.state.roleSel.Id;
    var idUser = this.$store.state.user.id;
    this.loadEnti(idUser, idRuolo, this.loadAllObjects);
  }

  loadAllObjects() {
    /*
    var enteId = this.$store.state.enteSel.id;
    var storico = false;
    // Oggetti principali
    // Veicoli
    this.loadVeicoli(enteId, storico);
    // Attrezzature
    this.loadAttrezzature(enteId, storico);
    // Fornitori
    var annoCorr = new Date().getFullYear(); // UtilsDate.getYearFromDate(UtilsDate.getNowDate());
    this.loadFornitori(annoCorr, enteId);
    // Scadenze
    // TODO
    // Rifornimenti
    // TODO
    // Buoni
    this.loadBuoni(annoCorr, enteId);
    */

    var enteId = this.$store.state.enteSel.id;
    var storico = false;
    this.loadDashboardValues(enteId, storico);

  }
/*
  loadVeicoli(enteId, storico) {
    var self = this;
    self.isloading_veicoli = true;

    VeicoliService.getVeicoli(
      enteId,
      storico,
      (resp) => {
        self.isloading_veicoli = false;
        console.log("Veicoli:");
        console.log(resp);
        console.log("Totale Veicoli:");
        console.log(resp.length);
        this.veicoliTot = resp.length;
        for (var i = 0, len = this.veicoliTot; i < len; i++) {
          if (resp[i].bInManut == "S") this.veicoliInManut++;
        }
        console.log("Veicoli in manutenz:");
        console.log(this.veicoliInManut);
      },
      (err) => {
        console.log(err);
        self.isAlertVisible = true;
        self.hide_alert();
        self.isloading_veicoli = false;
      }
    );
  }

  loadAttrezzature(enteId, storico) {
    var self = this;
    self.isloading_attrezz = true;

    AttrezzatureService.getAttrezzature(
      enteId,
      storico,
      (resp) => {
        self.isloading_attrezz = false;
        console.log("Attrezzature:");
        console.log(resp);
        console.log("Totale Attrezzature:");
        console.log(resp.length);
        this.attrezzTot = resp.length;
        for (var i = 0, len = this.attrezzTot; i < len; i++) {
          if (resp[i].bInManut == "S") this.attrezzInManut++;
        }
        console.log("Attrezzature in manutenz:");
        console.log(this.attrezzInManut);
      },
      (err) => {
        console.log(err);
        self.isAlertVisible = true;
        self.hide_alert();
        self.isloading_attrezz = false;
      }
    );
  }

  loadFornitori(anno, enteId) {
    var self = this;
    self.isloading_fornitori = true;

    FornitoriService.getFornDetermine(
      anno,
      enteId,
      (resp) => {
        self.isloading_fornitori = false;
        console.log("Fornitori:");
        console.log(resp);
        console.log("Totale Fornitori:");
        console.log(resp.length);
        this.fornitoriTot = resp.length;
        for (var i = 0, len = this.fornitoriTot; i < len; i++) {
          this.budgetAnnuale += parseInt(resp[i].budgetAnnuale);
          this.budgetUtilizzato += parseInt(resp[i].budgetUtilizzato);
        }
        
      },
      (err) => {
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoFornitori");
        self.showHide_alertGrid(5000);
        self.isloading_fornitori = false;
      }
    );
  }

  loadBuoni(anno, enteId) {
    var self = this;
    self.isloading_buoni = true;

    BuoniService.getBuoni(
      anno,
      enteId,
      (resp) => {
        self.isloading_buoni = false;
        console.log("Buoni:");
        console.log(resp);
        console.log("Totale Fornitori:");
        console.log(resp.length);
        
        for (var i = 0, len = resp.length; i < len; i++) {
          if (resp[i].buonoStatoDTO.cDescrizione == "Bozza") this.buoniLav++;
          if (resp[i].buonoStatoDTO.cDescrizione == "Emesso") this.buoniInv++;
        }
        console.log("Buoni inviati:");
        console.log(this.buoniInv);

      },
      (err) => {
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoBuoni");
        self.showHide_alertGrid(5000);
        self.isloading_buoni = false;
      }
    );
  }
*/
  loadEnti(idUser, idRuolo, callback) {
    var self = this;

    UtentiService.getUserEntiByRuolo(
      idUser,
      idRuolo,
      (resp) => {
        console.log("getUserEntiByRuolo");
        console.log(resp);

        self.eEnti = resp;

        if (this.$store.state.enteSel == null) {
            if (self.eEnti.length > 0)
              self.enteSel = self.eEnti[0];
            this.$store.commit("SET_ENTE_SELECTED", self.eEnti[0]);
        } else {
            self.enteSel = this.$store.state.enteSel;
          }
        if (self.eEnti.length == 1) self.shouldDisable = true;

        callback();
      },
      (err) => {
        console.log(err);
        self.isAlertVisible = true;
        self.hide_alert();
      }
    );
  }

  loadDashboardValues(enteId, storico) {
    var self = this;
    self.isloading_dashboard = true;

    DashboardService.getDashboardValues(
      enteId,
      storico,
      (resp) => {
        console.log("Dashboard:");
        console.log(resp);
        // Veicoli
        this.veicoliTot = resp.numVeicoli;
        this.veicoliInManut = resp.numVeicoliManut;
        // Attrezzature
        this.attrezzTot = resp.numAttrezzature;
        this.attrezzInManut = resp.numAttrezzatureManut;
        // Fornitori
        this.fornitoriTot = resp.numFornitori;
        this.budgetAnnuale = resp.numBudgetAnnuale;
        this.budgetUtilizzato =  resp.numBudgetUtilizzato;
        // Buoni
        this.buoniEmes = resp.numBuoniEmessi;
        this.buoniLav = resp.numBuoniLavorazione;
        // Scadenze
        this.scadInScadenza = resp.scadenzeInScadenza;
        this.scadScaduta = resp.scadenzeScadute;
        this.scadTotali = resp.scadenzeTotali;
        /// Rifornimenti
        this.rifornimentiMesePrecedenteChiusi = resp.rifornimentiMesePrecedenteChiusi;
        this.rifornimentiMesePrecedenteTot = resp.rifornimentiMesePrecedenteTot;
        this.rifornimentiMeseCorrenteChiusi = resp.rifornimentiMeseCorrenteChiusi;
        this.rifornimentiMeseCorrenteTot = resp.rifornimentiMeseCorrenteTot;

        self.isloading_dashboard = false;
      },
      (err) => {
        console.log(err);
        self.isAlertVisible = true;
        self.hide_alert();
        self.isloading_dashboard = false;
      }
    );
  }

  
}

/*
export default {
  name: "DashboardDashboard",


  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
  },

  created() {
    // Oggetti principali
    this.loadVeicoli();

    //this.veicoli.source = this.data.veicoliSource;
  },

  loadVeicoli() {
        var self = this
        //self.isloading_veicoli = true;
        axios.post('https://localhost:44317/api/Veicoli/GetVeicoliVW',
        {
        }
        ).then(resp => {

            //self.isloading_veicoli = false;
            console.log("Veicoli:")
            console.log(resp.data)
            self.veicoli.source = resp.data;

        })
        .catch(err => {
            console.log(err);
        })
    }
};
*/
</script>
